import React, { useEffect } from "react";
import { VacationRentalShopConnectorProps } from "./container";
import { RouteComponentProps } from "react-router";
import { Avatar, Box, Divider, Typography } from "@material-ui/core";
import {
  AmenityIcon,
  Icon,
  IconName,
  PageTabNavigations,
  pluralize,
  useDeviceTypes,
} from "halifax";
import { Circle, GoogleMap } from "@react-google-maps/api";
import "./styles.scss";
import {
  Amenity,
  Bathroom,
  Bedroom,
} from "redmond/apis/tysons/vacation-rentals";
import {
  AmenityCategoryEnum,
  AmenityCategoryIconMap,
  AmenityCategoryOrder,
  amenityKindToCategoryMap,
  MOST_POPULAR_AMENITIES,
} from "./constants";
import { TruncatedText } from "../TruncatedText/component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { ConnectedShopCta } from "../ShopCta/container";
import { IShopCtaVariant } from "../ShopCta/component";
import { ConnectedDesktopRewardsHeader } from "../../../rewards/components/DesktopRewardsHeader/container";
import { ConnectedRewardsAccountSelection } from "../../../rewards/components/RewardsAccountSelection/container";
import { ConnectedMobileShopHeader } from "../MobileShopHeader/container";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { VIEWED_VR_DETAILS } from "redmond";
import { ConnectedDesktopPhotoGallery } from "../DesktopPhotoGallery/container";
import { ConnectedMobilePhotoGallery } from "../MobilePhotoGallery/container";
import { VacationRentalTerms } from "../../../common/VacationRentalTerms/component";
import { VacationRentalImportantInformation } from "../../../common/VacationRentalImportantInformation/component";

export interface IShopProps
  extends VacationRentalShopConnectorProps,
    RouteComponentProps {}

export const Shop = (props: IShopProps) => {
  const { matchesDesktop, matchesMobile } = useDeviceTypes();

  const {
    selectedListing,
    fetchedVacationRentalDetails,
    viewedVacationRentalDetailsProperties,
  } = props;

  useEffect(() => {
    if (fetchedVacationRentalDetails) {
      trackEvent({
        eventName: VIEWED_VR_DETAILS,
        ...viewedVacationRentalDetailsProperties,
      });
    }
  }, [fetchedVacationRentalDetails]);

  // The store selectedListing should be populated in this codepath
  if (!selectedListing) {
    return null;
  }

  const { availability, listing } = selectedListing;
  const { content, hostInfo, terms } = listing;
  const { name, description, amenities, layout, location } = content;
  const {
    bathrooms,
    bedrooms,
    numberOfBeds,
    numberOfBedrooms,
    numberOfBathrooms,
  } = layout;

  const { hostName, hostImageUrl } = hostInfo || {};
  const { exactCoordinates } = location;

  const listingGoogleCoordinates = new google.maps.LatLng({
    lat: exactCoordinates.lat,
    lng: exactCoordinates.lon,
  });

  const {
    checkInTimes,
    checkOutTimes,
    checkinInformation,
    importantInformation,
    petsPolicy,
    petFriendly,
  } = terms;

  const { rate } = availability;

  const policy = rate?.cancellationPolicy?.policy;

  const categorizeAmenities = (amenities: Amenity[]) => {
    const categorizedAmenities = {} as any;

    amenities.map((amenity) => {
      const category =
        amenityKindToCategoryMap[amenity.kind] || AmenityCategoryEnum.General;
      categorizedAmenities[category]
        ? categorizedAmenities[category].push(amenity.displayName)
        : (categorizedAmenities[category] = [amenity.displayName]);
    });
    return categorizedAmenities;
  };

  const categorizedAmenities = categorizeAmenities(amenities);
  const categoriesSorted = Object.keys(categorizedAmenities).sort(
    (a, b) =>
      AmenityCategoryOrder.indexOf(a as AmenityCategoryEnum) -
      AmenityCategoryOrder.indexOf(b as AmenityCategoryEnum)
  );

  const scrollToSection = (section: string) => {
    const elem = window.document.getElementById(section);

    if (elem) {
      const offset = 50 + 80 + 60 + 30; // 50 (banner) + 80 (toolbar) + 60 (navigation) + 30 (padding)
      const y = elem.getBoundingClientRect().top + window.scrollY - offset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  const tabs = [
    {
      label: "Gallery",
      onClick: () => scrollToSection("gallery"),
    },
    { label: "Overview", onClick: () => scrollToSection("overview") },
    ...(bedrooms.length > 0
      ? [
          {
            label: "Rooms & beds",
            onClick: () => scrollToSection("rooms-and-beds"),
          },
        ]
      : []),
    {
      label: "About",
      onClick: () => scrollToSection("about"),
    },
    {
      label: "Amenities",
      onClick: () => scrollToSection("amenities"),
    },
    {
      label: "House rules",
      onClick: () => scrollToSection("house-rules"),
    },
  ];

  const renderMaxOccupancyIconLabel = (numGuest: number) => {
    return (
      numGuest > 0 && (
        <Box display="flex" alignItems={"center"}>
          <Icon name={IconName.UserOutline} className="icon-user-outline" />
          <Typography
            style={{ fontSize: "14px", color: "#141414", paddingLeft: "4px" }}
          >
            Up to {numGuest} {pluralize(numGuest, "guest", "guests")}
          </Typography>
        </Box>
      )
    );
  };

  const renderNumberOfBedroomsIconLabel = (numBedrooms: number) => {
    return (
      numBedrooms > 0 && (
        <Box display="flex" alignItems="center">
          <Icon name={IconName.BedOutlineIcon} className="icon-bedroom" />
          <Typography
            variant="body1"
            style={{ fontSize: "14px", color: "#141414", paddingLeft: "4px" }}
          >
            {numBedrooms} {pluralize(numBedrooms, "bedroom", "bedrooms")}
          </Typography>
        </Box>
      )
    );
  };

  const renderNumberOfBathroomsIconLabel = (numBathrooms: number) => {
    return (
      numBathrooms > 0 && (
        <Box display="flex" alignItems="center">
          <Icon name={IconName.BathTubOutlineIcon} className="icon-bathroom" />
          <Typography
            style={{ fontSize: "14px", color: "#141414", paddingLeft: "4px" }}
          >
            {numBathrooms} {pluralize(numBathrooms, "bathroom", "bathrooms")}
          </Typography>
        </Box>
      )
    );
  };

  const renderAmenityIconLabel = (amenity: Amenity) => {
    return (
      <Box display="flex" alignItems="flex-start" className="icon-fill-black">
        <AmenityIcon amenity={amenity.kind} />
        <Typography
          style={{
            fontSize: "14px",
            color: "#141414",
            paddingLeft: "4px",
          }}
        >
          {amenity.displayName}
        </Typography>
      </Box>
    );
  };

  const renderRoomOrBedCard = (bedrooms: Bedroom[], bathrooms: Bathroom[]) => {
    return (
      <Box display="flex" flexWrap="wrap" style={{ gap: "15px" }}>
        {bedrooms.map((bedroom: Bedroom, index) => {
          return (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              border="0.5px solid #CECFCD"
              width="100px"
              height="90px"
              padding="20px"
              borderRadius="8px"
              style={{ gap: "2px" }}
            >
              <Icon
                name={IconName.BedOutlineIcon}
                className="icon-bedroom-large"
              />
              <Typography variant="body1">Bedroom {index + 1}</Typography>
              <Typography
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#505050",
                }}
              >
                {bedroom.description}
              </Typography>
            </Box>
          );
        })}

        {bathrooms.map((bathroom, index) => {
          return (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              border="0.5px solid #CECFCD"
              width="100px"
              height="90px"
              padding="20px"
              borderRadius="8px"
              style={{ gap: "2px" }}
            >
              <Icon
                name={IconName.BathTubOutlineIcon}
                className="icon-bathroom-large"
              />
              <Typography variant="body1">Bathroom {index + 1}</Typography>
              <Typography
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#505050",
                }}
              >
                {bathroom.description}
              </Typography>
            </Box>
          );
        })}
      </Box>
    );
  };

  const renderOverview = (showPricing: boolean) => {
    return (
      <Box
        id="overview"
        marginBottom="25px"
        border="solid 0.5px #CECFCD"
        borderRadius="8px"
        style={{ backgroundColor: "white" }}
        padding="30px"
      >
        <Typography className="title">{name}</Typography>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          paddingBottom="20px"
          style={{ gap: "20px" }}
        >
          {renderMaxOccupancyIconLabel(numberOfBeds)}
          {renderNumberOfBedroomsIconLabel(numberOfBedrooms)}
          {renderNumberOfBathroomsIconLabel(numberOfBathrooms)}
        </Box>
        {showPricing && (
          <>
            <Divider style={{ marginBottom: "20px" }} />
            <Box display="flex" flexDirection="column" marginBottom="20px">
              <ConnectedShopCta variant={IShopCtaVariant.PRICING_ONLY} />
            </Box>
          </>
        )}
        <Divider style={{ marginBottom: "20px" }} />
        <Typography variant="h6" style={{ paddingBottom: "20px" }}>
          Most popular amenities
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          style={{ gap: "20px" }}
        >
          {amenities
            .filter((x) => MOST_POPULAR_AMENITIES.includes(x.kind))
            .map((y) => renderAmenityIconLabel(y))}
        </Box>
      </Box>
    );
  };

  const renderRoomsAndBeds = () => {
    return (
      bedrooms.length > 0 && (
        <Box id="rooms-and-beds" className="section">
          <Typography className="title">Rooms and beds</Typography>
          <Box paddingBottom="20px">
            {renderRoomOrBedCard(bedrooms, bathrooms)}
          </Box>
        </Box>
      )
    );
  };

  const renderAbout = () => {
    return (
      <Box id="about" className="section">
        <Typography className="title">About</Typography>
        <Box display="flex" flexDirection="column" style={{ gap: "20px" }}>
          {hostName && (
            <Box display="flex" alignItems="center" style={{ gap: "15px" }}>
              <Avatar src={hostImageUrl} />
              <Typography variant="h6" style={{ color: "#141414" }}>
                {hostName ? `Hosted by ${hostName}` : "Your host"}
              </Typography>
            </Box>
          )}

          <TruncatedText text={description} limit={300} setAsHTML={true} />

          <Box style={{ height: "250px", width: "auto" }}>
            <GoogleMap
              mapContainerStyle={{ height: "100%", width: "100%" }}
              options={{
                clickableIcons: false,
                zoomControl: true,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
              }}
              center={listingGoogleCoordinates}
              zoom={15}
            >
              <Circle
                center={listingGoogleCoordinates}
                options={{
                  center: listingGoogleCoordinates,
                  radius: 125,
                  strokeColor: "#fff",
                  strokeOpacity: 1,
                  strokeWeight: 1,
                  fillColor: "rgba(2, 118, 177, 1.0)",
                  fillOpacity: 0.35,
                }}
              />
            </GoogleMap>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderAmenities = () => {
    return (
      <Box id="amenities" className="section">
        <Typography className="title">Amenities and features</Typography>
        <Box style={{ columnCount: 2 }}>
          {categoriesSorted.map((category) => (
            <Box
              display={"inline-block"}
              width={"100%"}
              pb="20px"
              key={category}
            >
              <Box
                display="flex"
                flexDirection="column"
                style={{ gap: "10px" }}
              >
                <Box display="flex" alignItems="center" style={{ gap: "10px" }}>
                  {AmenityCategoryIconMap[category]}
                  <Typography variant="h6">{category}</Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                  {categorizedAmenities[category].map((amenity: string) => (
                    <Box
                      display="flex"
                      style={{ gap: "10px" }}
                      paddingBottom="10px"
                      alignItems={"center"}
                      key={amenity}
                    >
                      <FontAwesomeIcon
                        width={"20px"}
                        height={"20px"}
                        style={{ opacity: 0.5 }}
                        color="#AAAAAA"
                        size="sm"
                        icon={faCheck}
                      />
                      <Typography>{amenity}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  return (
    <Box className="vacation-rental-shop" style={{ backgroundColor: "white" }}>
      {/* Mobile */}
      {matchesMobile && (
        <Box className="shop-mobile" display="flex" flexDirection="column">
          <ConnectedMobileShopHeader />
          <Box
            padding="18px"
            style={{ backgroundColor: "#FBFBFB" }}
            borderBottom="0.5px solid var(--grey-11)"
          >
            <ConnectedRewardsAccountSelection />
          </Box>
          <Box id="gallery">
            <ConnectedMobilePhotoGallery />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            padding="20px"
            style={{ backgroundColor: "#FBFBFB" }}
          >
            {renderOverview(true)}
            {renderRoomsAndBeds()}
            {renderAbout()}
            {renderAmenities()}
            <VacationRentalTerms
              checkinInformation={checkinInformation}
              checkInTimes={checkInTimes}
              checkOutTimes={checkOutTimes}
              petFriendly={petFriendly}
              petsPolicy={petsPolicy}
              cancellationPolicy={policy}
            />
            {importantInformation && (
              <Box className="important-info">
                <VacationRentalImportantInformation
                  importantInformation={importantInformation}
                />
              </Box>
            )}
          </Box>
          <Box
            position="fixed"
            bottom="0px"
            boxShadow="0px -2px 10px rgba(0, 0, 0, 0.2)"
            width="100%"
          >
            <ConnectedShopCta variant={IShopCtaVariant.CTA_BUTTON_ONLY} />
          </Box>
        </Box>
      )}

      {/* Desktop */}
      {matchesDesktop && (
        <Box className="shop-desktop" display="flex" flexDirection="column">
          <ConnectedDesktopRewardsHeader />
          <Box id="gallery" paddingX="120px">
            <ConnectedDesktopPhotoGallery />
          </Box>
          <PageTabNavigations
            className="navigation-overrides"
            navItems={tabs}
            selectedTab={0}
            rightContent={<></>}
          />
          <Box
            display="flex"
            flexDirection="row"
            style={{ gap: "20px", backgroundColor: "#FBFBFB" }}
            paddingX="120px"
            paddingY="30px"
          >
            <Box display="flex" flexDirection="column">
              {renderOverview(false)}

              {renderRoomsAndBeds()}

              {renderAbout()}

              {renderAmenities()}

              <VacationRentalTerms
                checkinInformation={checkinInformation}
                checkInTimes={checkInTimes}
                checkOutTimes={checkOutTimes}
                petFriendly={petFriendly}
                petsPolicy={petsPolicy}
                cancellationPolicy={policy}
              />
              {importantInformation && (
                <Box className="important-info">
                  <VacationRentalImportantInformation
                    importantInformation={importantInformation}
                  />
                </Box>
              )}
            </Box>

            <Box
              position="sticky"
              width="500px"
              height="100%"
              style={{ top: "220px", backgroundColor: "white" }}
              display="flex"
              flexDirection="column"
              border="solid 0.5px #CECFCD"
              borderRadius="8px"
            >
              <ConnectedShopCta variant={IShopCtaVariant.DESKTOP} />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
