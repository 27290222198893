import { Link, Typography } from "@material-ui/core";
import React from "react";
import { useState } from "react";

const trimDetails = (text: string, limit: number) => {
  if (!text) return { trimmedString: null, showMore: false };
  // Render the show more only if there is a significant amount of text after the limit.
  // This prevents issues where the show more only reveals a few characters.
  if (text.length <= limit || text.length - limit < limit / 2) {
    return {
      trimmedString: text,
      showMore: false,
    };
  }

  const trimmedString = text.substring(0, limit);
  return {
    trimmedString: `${trimmedString}...`,
    showMore: true,
  };
};

export interface TruncatedTextProps {
  text: string;
  limit?: number;
  setAsHTML?: boolean;
}

export const TruncatedText = ({
  text,
  limit,
  setAsHTML,
}: TruncatedTextProps) => {
  const [open, setOpen] = useState<boolean | null>(false);
  const { trimmedString, showMore } = trimDetails(text, limit || 300);
  const textToRender = open ? text : trimmedString;
  return (
    <>
      {!!textToRender &&
        (setAsHTML ? (
          <Typography
            dangerouslySetInnerHTML={{
              __html: textToRender.replace(/\n/g, "<br/>"),
            }}
          />
        ) : (
          <Typography>{textToRender}</Typography>
        ))}

      {showMore && (
        <Link
          variant="h6"
          style={{ color: "#013D58" }}
          onClick={() => {
            setOpen(!open);
          }}
        >
          {open ? "Read less" : "Read more"}
        </Link>
      )}
    </>
  );
};
